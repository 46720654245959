//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { LocalStorage } from "quasar";
import enter from "../../../assets/joobbook/enter.png";
const EventUtil = {
  addHandler: function (element, type, handler) {
    if (element.addEventListener)
      element.addEventListener(type, handler, false);
    else if (element.attachEvent) element.attachEvent("on" + type, handler);
    else element["on" + type] = handler;
  },
  removeHandler: function (element, type, handler) {
    if (element.removeEventListener)
      element.removeEventListener(type, handler, false);
    else if (element.detachEvent) element.detachEvent("on" + type, handler);
    else element["on" + type] = handler;
  },
  /**
   * 监听触摸的方向
   * @param target            要绑定监听的目标元素
   * @param isPreventDefault  是否屏蔽掉触摸滑动的默认行为（例如页面的上下滚动，缩放等）
   * @param upCallback        向上滑动的监听回调（若不关心，可以不传，或传false）
   * @param rightCallback     向右滑动的监听回调（若不关心，可以不传，或传false）
   * @param downCallback      向下滑动的监听回调（若不关心，可以不传，或传false）
   * @param leftCallback      向左滑动的监听回调（若不关心，可以不传，或传false）
   */
  listenTouchDirection: function (
    target,
    isPreventDefault,
    upCallback,
    rightCallback,
    downCallback,
    leftCallback
  ) {
    this.addHandler(target, "touchstart", handleTouchEvent);
    this.addHandler(target, "touchend", handleTouchEvent);
    this.addHandler(target, "touchmove", handleTouchEvent);
    let startX;
    let startY;
    function handleTouchEvent(event) {
      switch (event.type) {
        case "touchstart":
          startX = event.touches[0].pageX;
          startY = event.touches[0].pageY;
          break;
        case "touchend":
          {
            let spanX = event.changedTouches[0].pageX - startX;
            let spanY = event.changedTouches[0].pageY - startY;

            if (Math.abs(spanX) > Math.abs(spanY)) {
              //认定为水平方向滑动
              if (spanX > 30) {
                //向右
                if (rightCallback) rightCallback();
              } else if (spanX < -30) {
                //向左
                if (leftCallback) leftCallback();
              }
            } else {
              //认定为垂直方向滑动
              if (spanY > 30) {
                //向下
                if (downCallback) downCallback();
              } else if (spanY < -30) {
                //向上
                if (upCallback) upCallback();
              }
            }
          }

          break;
        case "touchmove":
          //阻止默认行为
          if (isPreventDefault) event.preventDefault();
          break;
        default:
      }
    }
  },
};
export default {
  name: "Index",
  data() {
    return {
      name: null,
      code: null,
      currentYear: this.$moment().format("yyyy"),
      currentMonth: this.$moment().format("MM"),
      yearList: [],
      monthList: [],
      monthBarWidth: 0,
      dataList: [],
      enter,
      DHdataList: [],
      DZdataList: [],
    };
  },
  activated() {
    this.$dd.ready(() => {
      this.$dd.biz.navigation.setTitle({
        title: "报工",
      });
    });
    if (this.code) {
      this.getData();
    }
  },
  created() {
    this.$dd.ready(() => {
      this.$dd.biz.navigation.setTitle({
        title: "报工",
      });
    });
    this.code = LocalStorage.getItem("jobnumber");
    this.name = LocalStorage.getItem("userName");
    let startYear = 2021;
    let endYear = new Date().getFullYear();
    for (let i = startYear; i <= endYear; i++) {
      this.yearList.unshift({
        text: i + "",
        value: i + "",
      });
    }
    let currentMonth = new Date().getMonth();

    for (let i = 0; i <= currentMonth; i++) {
      if (i < 9) {
        this.monthList.push(this.monthTxtTrans("0" + (i + 1)));
      } else {
        this.monthList.push(this.monthTxtTrans("" + (i + 1)));
      }
    }

    this.monthBarWidth = this.monthList.length * 50;
    //this.getData();
  },
  mounted() {
    this.$dd.ready(() => {
      this.$dd.biz.navigation.setTitle({
        title: "报工",
      });
    });
    let currentMonth = new Date().getMonth();
    document.getElementById("monthSelector").scrollLeft = currentMonth * 50;

    EventUtil.listenTouchDirection(
      document.getElementById("monthContent"),
      true,
      this.up,
      this.right,
      this.down,
      this.left
    );
  },
  methods: {
    up() {},
    right() {
      console.log("向右滑动 月份减touchRight");
      console.log(this.currentMonth);
      console.log(this.monthList);

      if (
        this.monthList.length <= 1 ||
        this.monthTxtTrans(this.currentMonth) == this.monthList[0]
      ) {
        return;
      }
      this.changeMonthByTouch("-");
      this.getData();
    },
    down() {},
    left() {
      console.log("向左滑动 月份加touchLeft");

      if (
        this.monthList.length <= 1 ||
        this.monthTxtTrans(this.currentMonth) ==
          this.monthList[this.monthList.length - 1]
      ) {
        return;
      }
      this.changeMonthByTouch("+");
      this.getData();
    },
    changeMonth(monthTxt) {
      this.currentMonth = this.monthTxtTrans(monthTxt);
      this.getData();
    },
    changeMonthByTouch(flag) {
      let beforeMonth = this.currentMonth;
      console.log(beforeMonth);
      switch (flag) {
        case "-":
          if (Number(beforeMonth) > 1) {
            let toMonth = Number(beforeMonth) - 1;
            this.currentMonth = toMonth > 9 ? "" + toMonth : "0" + toMonth;
          }
          break;
        case "+":
          {
            let maxMonth = 12;
            if (Number(this.currentYear) == new Date().getFullYear()) {
              maxMonth = new Date().getMonth() + 1;
            }
            console.log(maxMonth);
            if (Number(beforeMonth) < maxMonth) {
              let toMonth = Number(beforeMonth) + 1;
              this.currentMonth = toMonth > 9 ? "" + toMonth : "0" + toMonth;
            }
          }
          break;
      }
      document.getElementById("monthSelector").scrollLeft =
        (Number(this.currentMonth) - 1) * 50;
    },
    monthTxtTrans(value) {
      switch (value) {
        case "01":
          return "1月";
        case "1月":
          return "01";
        case "02":
          return "2月";
        case "2月":
          return "02";
        case "03":
          return "3月";
        case "3月":
          return "03";
        case "04":
          return "4月";
        case "4月":
          return "04";
        case "05":
          return "5月";
        case "5月":
          return "05";
        case "06":
          return "6月";
        case "6月":
          return "06";
        case "07":
          return "7月";
        case "7月":
          return "07";
        case "08":
          return "8月";
        case "8月":
          return "08";
        case "09":
          return "9月";
        case "9月":
          return "09";
        case "10":
          return "10月";
        case "10月":
          return "10";
        case "11":
          return "11月";
        case "11月":
          return "11";
        case "12":
          return "12月";
        case "12月":
          return "12";
      }
    },
    yearChange() {
      let currentYear = this.currentYear;
      console.log(currentYear);
      this.getMonthList(currentYear);
    },
    getMonthList(year) {
      let nowYear = new Date().getFullYear() + "";
      console.log(nowYear);
      this.monthList = [];
      let endMonth = "";
      if (year == nowYear) {
        endMonth = new Date().getMonth();
        this.currentMonth = this.$moment().format("MM");
      } else {
        endMonth = 11;
        this.currentMonth = "01";
      }
      //今年
      for (let i = 0; i <= endMonth; i++) {
        if (i < 9) {
          this.monthList.push(this.monthTxtTrans("0" + (i + 1)));
        } else {
          this.monthList.push(this.monthTxtTrans("" + (i + 1)));
        }
      }
      this.monthBarWidth = this.monthList.length * 50;
      console.log(this.currentMonth);
      document.getElementById("monthSelector").scrollLeft =
        (Number(this.currentMonth) - 1) * 50;
      this.getData();
    },
    getData() {
      this.axios
        .post("/mes/jobbook/getJobbookDataMonth", {
          queryMap: {
            indate: this.currentYear + "" + this.currentMonth,
          },
        })
        .then((res) => {
          console.log(res);
          if (res && res.data && res.data.code && res.data.code === "SUCCESS") {
            this.dataList = res.data.data;
          } else {
            this.$toast.fail(res.data.msg);
          }
        });
    },
    gotoSetjob() {
      this.$router.push(
        "/jobbook/setjob?name=" + this.name + "&code=" + this.code
      );
    },
    gotoDetail() {
      this.$router.push(
        "/jobbook/detail?year=" +
          this.currentYear +
          "&month=" +
          this.currentMonth
      );
    },
  },
  watch: {
    dataList(newer, older) {
      console.log(older);
      this.DHdataList = newer.filter((item) => {
        return item.businessType == "BULK";
      });

      this.DZdataList = newer.filter((item) => {
        return item.businessType == "MTM";
      });
    },
  },
};
